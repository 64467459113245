.cta-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
}

@media screen and (min-width: 768px) {
  .cta-button {
    padding: 15px;
  }
}

@media screen and (min-width: 992px) {
  .cta-button {
    padding: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .cta-button {
    padding: 15px;
  }
}

.cta-button .top-txt {
  font-size: 19px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .cta-button .top-txt {
    font-size: 21px;
  }
}

@media screen and (min-width: 992px) {
  .cta-button .top-txt {
    font-size: 23px;
  }
}

@media screen and (min-width: 1200px) {
  .cta-button .top-txt {
    font-size: 25px;
  }
}

.cta-button .seperator {
  display: block;
  width: 50px;
  height: 1px;
  transition: all 250ms ease;
  margin-left: auto;
  margin-right: auto;
}

.cta-button .bottom-txt {
  margin-top: 6px;
}

.cta-button.btn--primary .seperator {
  border-color: #29363E;
}

.cta-button.btn--primary:hover .seperator, .cta-button.btn--primary:focus .seperator {
  border-color: #FFF;
}

.cta-button.btn--secundary .seperator {
  border-color: #FFD400;
}

.cta-button.btn--secundary:hover .seperator, .cta-button.btn--secundary:focus .seperator {
  border-color: #121012;
}
