@import "./_sass-essentials/essentials.scss";

.cta-button {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include resp-prop('padding', 10px, 15px, 15px, 15px);

  .top-txt {
    @include resp-prop('font-size', 19px, 21px, 23px, 25px);
    font-weight: $fw-bold;
  }

  .seperator {
    display: block;
    width: 50px;
    height: 1px;
    @include transition();
    margin-left: auto;
    margin-right: auto;
  }

  .bottom-txt {
    margin-top: 6px;
  }

  &.btn--primary {
    .seperator {
      border-color: map-get($c-btn-primary, txt);
    }

    &:hover,
    &:focus {
      .seperator {
        border-color: map-get($c-btn-primary, txt-hov);
      }
    }
  }

  &.btn--secundary {
    .seperator {
      border-color: map-get($c-btn-secundary, txt);
    }

    &:hover,
    &:focus {
      .seperator {
        border-color: map-get($c-btn-secundary, txt-hov);
      }
    }
  }
}
